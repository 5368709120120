module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/fingenious-site/src/translations","languages":["en"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P927SZJ","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fingenious","short_name":"Fingenious","start_url":"/","background_color":"#113786","theme_color":"#113786","display":"standalone","icon":"src/images/fingenious-favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7aaaa1152df562815b9bf922143b1613"},
    },{
      plugin: require('../../finbb-dms-theme/gatsby-browser.js'),
      options: {"plugins":[],"name":"FINGENIOUS","languages":{"en":"en-US"},"templates":{"ContentfulArticlePage":"/opt/build/repo/fingenious-site/src/templates/article-page.tsx","ContentfulPage":"/opt/build/repo/fingenious-site/src/templates/page.tsx"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
